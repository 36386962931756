import T1 from '../../assets/img/_coach-1.jpg';
import T2 from '../../assets/img/_coach-2.jpg';
import T3 from '../../assets/img/_coach-3.jpg';
import T4 from '../../assets/img/_coach-4.jpg';
import TeamSmall from '../PropsComponents/TeamSmall';
import TitlePinkBlack from '../PropsComponents/TitlePinkBlack';
export default function HomeTeam() {
    const TeamData = [
        {image: T1,nameBlack:'Alex' , namePink:'Conaughey' , age:'26' , exp:"8 years" , proff:'Gym'},
        {image: T2,nameBlack:'Paul' , namePink:'Hudson' , age:'26' , exp:"8 years" , proff:'Gym'},
        {image: T3,nameBlack:'Jenny' , namePink:'Paster' , age:'26' , exp:"8 years" , proff:'Gym'},
        {image: T4,nameBlack:'Nikolas' , namePink:'Paster' , age:'26' , exp:"8 years" , proff:'Gym'}
    ]

    return(
        <div className={'bg-[#F8F6F9]  pb-24'} id="Team">
            <div className='max-w-screen-xl py-12 text-center  mx-auto max-xl:px-6'>
                <TitlePinkBlack titleBlack={'Our'} titlePink = {'Coaches'} />
            </div>
         <div className='max-w-screen-xl mt-6 flex mx-auto max-lg:flex-wrap lg:space-x-10  max-xl:px-6'>
         {
                TeamData && TeamData.map((item,index) => {
                  return  <TeamSmall image={item.image} nameBlack={item.nameBlack} namePink={item.namePink} age={item.age} exp={item.exp} proff={item.proff} />
                })
            }
         </div>
        </div>
    )
}