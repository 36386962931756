import {FaCheck} from "react-icons/fa";

export default function HomeTrainingPlan() {
    return (
        <div className="bg-after-blog_paralax bg-cover bg-no-repeat bg-center bg-fixed py-20 mt-12" id="Pricing">
            <div className="max-w-screen-xl  mx-auto">
                <div className="w-[100%]  text-center">
                    <div className="text-[#fff] font-Fira font-black text-[36px] leading-none text-center w-full ">Choose your personal</div>
                    <div className="text-mainPink dark:text-mainBlue font-Fira font-black text-[36px] mt-2 leading-none transition-all duration-300 text-center w-full ">
                        training plan</div>
                </div>
                <div className="w-full flex max-lg:flex-wrap lg:space-x-10 mt-24 max-xl:px-6">
                    <div className="transition-all duration-500 cursor-pointer  hover:-translate-y-4 w-full sm:w-[40%] mx-auto lg:w-1/4 rounded-[48px] overflow-hidden">
                        <div className="bg-[#6f6078] dark:bg-[#E0E0E0] px-[35px] py-[30px]">
                            <div className="text-center text-[17px] font-bold">1 training</div>
                            <div className="font-Fira uppercase font-black text-center text-white text-[45px]">FREE</div>
                        </div>
                        <div className="bg-[#FFF] px-[20px]  xl:px-[35px] py-[30px]">
                           <ul className="flex  flex-col space-y-3">
                                <li className="flex items-center space-x-4">
                                    <FaCheck className="text-mainPink dark:text-mainBlue font-black text-[14px]" /> <span className="text-[#422055]">Cardio</span>
                                </li>
                                <li className="flex items-center space-x-4">
                                    <FaCheck className="text-white" /> <span className="text-[#dcd4e0]">Swimming Pool</span>
                                </li>
                                <li className="flex items-center space-x-4">
                                    <FaCheck className="text-mainPink dark:text-mainBlue font-black text-[14px]" /> <span className="text-[#422055]">Gym</span>
                                </li>
                                <li className="flex items-center space-x-4">
                                    <FaCheck className="text-white" /> <span className="text-[#dcd4e0]">Group Lessons</span>
                                </li>
                                <li className="flex items-center space-x-4">
                                    <FaCheck className="text-white" /> <span className="text-[#dcd4e0]">Aerobics</span>
                                </li>
                                <li className="flex items-center space-x-4">
                                    <FaCheck className="text-white" /> <span className="text-[#dcd4e0]">Crossfit</span>
                                </li>
                           </ul>
                        </div>
                    </div>
                    <div className="transition-all duration-500 cursor-pointer  hover:-translate-y-4 w-full sm:w-[40%] mx-auto max-lg:mt-6 lg:w-1/4 rounded-[48px] overflow-hidden">
                        <div className="bg-mainPink dark:bg-mainBlue px-[35px] py-[30px]">
                            <div className="text-center text-[17px] font-bold flex items-center justify-center "><span className="w-8 mr-2 h-8 bg-black
                             rounded-full flex justify-center items-center text-white relative">3</span> trainings</div>
                            <div className="font-Fira uppercase text-center text-white text-[45px] font-black">$ 25</div>
                        </div>
                        <div className="bg-[#FFF] px-[20px]  xl:px-[35px] py-[30px]">
                           <ul className="flex  flex-col space-y-3">
                                <li className="flex items-center space-x-4">
                                    <FaCheck className="text-mainPink dark:text-mainBlue font-black text-[14px]" /> <span className="text-[#422055]">Cardio</span>
                                </li>
                                <li className="flex items-center space-x-4">
                                    <FaCheck className="text-white" /> <span className="text-[#dcd4e0]">Swimming Pool</span>
                                </li>
                                <li className="flex items-center space-x-4">
                                    <FaCheck className="text-mainPink dark:text-mainBlue font-black text-[14px]"  /> <span className="text-[#422055]">Gym</span>
                                </li>
                                <li className="flex items-center space-x-4">
                                    <FaCheck className="text-mainPink dark:text-mainBlue font-black text-[14px]" /> <span className="text-[#422055]">Group Lessons</span>
                                </li>
                                <li className="flex items-center space-x-4">
                                    <FaCheck className="text-mainPink dark:text-mainBlue font-black text-[14px]" /> <span className="text-[#422055]">Aerobics</span>
                                </li>
                                <li className="flex items-center space-x-4">
                                    <FaCheck className="text-mainPink dark:text-mainBlue font-black text-[14px]" /> <span className="text-[#422055]">Crossfit</span>
                                </li>
                           </ul>
                        </div>
                    </div>
                    <div className="transition-all duration-500 cursor-pointer  hover:-translate-y-4 w-full sm:w-[40%] mx-auto max-lg:mt-6 lg:w-1/4 rounded-[48px] overflow-hidden">
                        <div className="bg-mainPink dark:bg-mainBlue px-[35px] py-[30px]">
                            <div className="text-center text-[17px] font-bold flex items-center justify-center "><span className="w-8 mr-2 h-8 bg-black
                             rounded-full flex justify-center items-center text-white relative">3</span> trainings</div>
                            <div className="font-Fira uppercase text-center text-white font-black text-[45px]">$ 49</div>
                        </div>
                        <div className="bg-[#FFF]  px-[20px]  xl:px-[35px] py-[30px]">
                           <ul className="flex  flex-col space-y-3">
                                <li className="flex items-center space-x-4">
                                    <FaCheck className="text-mainPink dark:text-mainBlue font-black text-[14px]" /> <span className="text-[#422055]">Cardio</span>
                                </li>
                                <li className="flex items-center space-x-4">
                                    <FaCheck className="text-white" /> <span className="text-[#dcd4e0]">Swimming Pool</span>
                                </li>
                                <li className="flex items-center space-x-4">
                                    <FaCheck className="text-mainPink dark:text-mainBlue font-black text-[14px] " /> <span className="text-[#422055]">Gym</span>
                                </li>
                                <li className="flex items-center space-x-4">
                                    <FaCheck className="text-mainPink dark:text-mainBlue font-black text-[14px]" /> <span className="text-[#422055]">Group Lessons</span>
                                </li>
                                <li className="flex items-center space-x-4">
                                    <FaCheck className="text-mainPink dark:text-mainBlue font-black text-[14px]" /> <span className="text-[#422055]">Aerobics</span>
                                </li>
                                <li className="flex items-center space-x-4">
                                    <FaCheck className="text-mainPink dark:text-mainBlue font-black text-[14px]" /> <span className="text-[#422055]">Crossfit</span>
                                </li>
                           </ul>
                        </div>
                    </div>
                    <div className="transition-all duration-500 cursor-pointer  hover:-translate-y-4 w-full sm:w-[40%] mx-auto max-lg:mt-6 lg:w-1/4 rounded-[48px] overflow-hidden">
                        <div className="bg-mainPink dark:bg-mainBlue px-[35px] py-[30px]">
                            <div className="text-center text-[17px] font-bold flex items-center justify-center "><span className="w-8 mr-2 h-8 bg-black
                             rounded-full flex justify-center items-center text-white relative">3</span> trainings</div>
                            <div className="font-Fira uppercase text-center font-black text-white text-[45px]">$ 129</div>
                        </div>
                        <div className="bg-[#FFF]  px-[20px]  xl:px-[35px] py-[30px]">
                           <ul className="flex  flex-col space-y-3">
                                <li className="flex items-center space-x-4">
                                    <FaCheck className="text-mainPink dark:text-mainBlue font-black text-[14px]" /> <span className="text-[#422055]">Cardio</span>
                                </li>
                                <li className="flex items-center space-x-4">
                                    <FaCheck className="text-mainPink dark:text-mainBlue font-black text-[14px]" /> <span className="text-[#422055]">Swimming Pool</span>
                                </li>
                                <li className="flex items-center space-x-4">
                                    <FaCheck className="text-mainPink dark:text-mainBlue font-black text-[14px] " /> <span className="text-[#422055]">Gym</span>
                                </li>
                                <li className="flex items-center space-x-4">
                                    <FaCheck className="text-mainPink dark:text-mainBlue font-black text-[14px]" /> <span className="text-[#422055]">Group Lessons</span>
                                </li>
                                <li className="flex items-center space-x-4">
                                    <FaCheck className="text-mainPink dark:text-mainBlue font-black text-[14px]" /> <span className="text-[#422055]">Aerobics</span>
                                </li>
                                <li className="flex items-center space-x-4">
                                    <FaCheck className="text-mainPink dark:text-mainBlue font-black text-[14px]" /> <span className="text-[#422055]">Crossfit</span>
                                </li>
                           </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}