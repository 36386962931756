import React from "react";
import { Link } from "react-scroll";
import "../../assets/js/script";
import Im1 from "../../assets/img/download.png";
export default function HomeFirst() {


    return (
        <>

            <div id="Home" className="relative max-sm:py-[200px] sm:h-[100vh]">
                <div id="background-slide1" className="background-slider"></div>
                <div id="background-slide2" className="background-slider"></div>
                <div id="background-slide0" className="background-slider"></div>

                <div className="w-full  top-0 left-0 h-full flex items-center">
                    <div className="w-full mx-auto flex justify-center items-center max-xl:px-12">
                        <div className="max-w-screen-xl w-full">
                            <div className="lg:w-1/2">
                                <div className="text-[26px]  sm:text-[50px] xl:text-[80px] text-white font-Fira leading-none font-black">
                                    Lose your weight <span className="xl:text-[58px] text-mainPink dark:text-mainBlue transition-all duration-300">in just 2 weeks</span>
                                </div>
                                <p className="text-[18px] max-md:hidden text-white pr-8 mt-3 xl:mt-12">
                                    Phasellus placerat magna dolor, vel dictum velit sodales id. Fusce molestie metus magna, ut faucibus eros hendrerit porta. Fusce aliquet varius tristique.
                                </p>
                                <div className="mt-3 max-sm:mt-12 xl:mt-12">
                                    <Link to="/Contact" className="py-[14px] px-[60px] bg-mainPink dark:bg-mainBlue text-white rounded-2xl transition-all duration-300 text-[18px] cursor-pointer hover:bg-white hover:text-[#337ab7]">Contact Us</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="absolute bottom-0 border-b-[4px] border-white w-full flex justify-center">
                    <img src={Im1} className="relative top-[4px]" />
                </div>
            </div>
        </>
    )
}