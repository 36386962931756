import CountUp from 'react-countup';

export default function HomeSkills() {
    return (
        <>
            <div className='w-full bg-skills-parallax bg-cover bg-center bg-fixed py-12 pb-48'>
                <div className='max-w-screen-xl mx-auto'>
                    <div className='text-center'>
                        <div className="font-black text-center font-Fira text-white text-[36px]">Our <span className="transition-all duration-300 text-mainPink dark:text-mainBlue">skills</span></div>
                    </div>
                </div>
                <div className='max-w-screen-xl max-md:flex-wrap md:space-x-8 mx-auto flex mt-6 max-xl:px-6'>
                    <div className='max-md:mt-6 w-[90%] max-sm:h-[180px] mx-auto sm:w-1/2 md:w-1/4 border-[2px] border-white/10 rounded-[40px] pt-[20px] pb-[20px] px-[10px] lg:px-[20px]'>
                        <div className='text-white text-[50px] sm:text-[6vw] font-black text-center font-Fira'>
                            <CountUp start={0} end={794} duration={2.5} />
                        </div>
                        <div className='text-[15px] lg:text-[17px] transition-all duration-300 text-mainPink dark:text-mainBlue text-center font-bold'>happy customers</div>
                    </div>
                    <div className='max-md:mt-6 w-[90%] max-sm:h-[180px] mx-auto sm:w-1/2 md:w-1/4 border-[2px] border-white/10 rounded-[40px] pt-[20px] pb-[20px] px-[10px] lg:px-[20px]'>
                        <div className='text-white text-[50px] sm:text-[6vw] font-black text-center font-Fira'>
                            <CountUp start={0} end={97} duration={2.5} />
                        </div>
                        <div className='text-[15px] lg:text-[17px] transition-all duration-300 text-mainPink dark:text-mainBlue text-center font-bold'>individual nutrition  <br /> programs</div>
                    </div>
                    <div className='max-md:mt-6 w-[90%] max-sm:h-[180px] mx-auto sm:w-1/2 md:w-1/4 border-[2px] border-white/10 rounded-[40px] pt-[20px] pb-[20px] px-[10px] lg:px-[20px]'>
                        <div className='text-white text-[50px] sm:text-[6vw] font-black text-center font-Fira'>
                            <CountUp start={0} end={130} duration={2.5} />
                        </div>
                        <div className='text-[15px] lg:text-[17px] transition-all duration-300 text-mainPink dark:text-mainBlue text-center font-bold'>high quality equipment</div>
                    </div>
                    <div className='max-md:mt-6 w-[90%] max-sm:h-[180px] mx-auto sm:w-1/2 md:w-1/4 border-[2px] border-white/10 rounded-[40px] pt-[20px] pb-[20px] px-[10px] lg:px-[20px]'>
                        <div className='text-white text-[50px] sm:text-[6vw] font-black text-center font-Fira'>
                            <CountUp start={0} end={26} duration={2.5} />
                        </div>
                        <div className='text-[15px] lg:text-[17px] transition-all duration-300 text-mainPink dark:text-mainBlue text-center font-bold'>champions trained</div>
                    </div>
                </div>
            </div>
        </>
    )
}