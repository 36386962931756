import TitlePinkBlack from "../PropsComponents/TitlePinkBlack";
import F1Img from "../../assets/img/_food-1.jpg";
import F2Img from "../../assets/img/_food-2.jpg";
import F3Img from "../../assets/img/_food-3.jpg";
import SmallBlog from "../PropsComponents/SmallBlog";
export default function HomeBlog() {
    const Blogs = [
        { image: F1Img, title: "The best choice for your muscles", desc: "Quisque quis dignissim elit. Aliquam et augue aliquet orci maximus convallis id vitae augue. Phasellus elementum commodo aliquet. Sed vitae sem in arcu auctor pharetra vel ut erat.", date: 'January 2, 2017', like: '212', comment: "4" },
        { image: F2Img, title: "10 ways to include mushrooms", desc: "Quisque quis dignissim elit. Aliquam et augue aliquet orci maximus convallis id vitae augue. Phasellus elementum commodo aliquet. Sed vitae sem in arcu auctor pharetra vel ut erat.", date: 'January 9, 2018', like: '162', comment: "14" },
        { image: F3Img, title: "How to choose the meat?", desc: "Quisque quis dignissim elit. Aliquam et augue aliquet orci maximus convallis id vitae augue. Phasellus elementum commodo aliquet. Sed vitae sem in arcu auctor pharetra vel ut erat.", date: 'January 6, 2018', like: '55', comment: "9" }
    ]
    return (
        <>
            <div className="bg-white  after:transition-all after:ease-linear ease-linear after:duration-300 py-24 transition-all duration-300 relative after:absolute after:w-full  after:left-0 after:bg-after-bg  after:h-12 after:bg-no-repeat after:bg-center after:-top-11" id="Blog">
                <div className="max-w-screen-xl mx-auto">
                    <div className='max-w-screen-xl text-center  mx-auto max-xl:px-6'>
                        <TitlePinkBlack titleBlack={'Nutrition'} titlePink={' blog'} />
                    </div>
                    <div className='max-w-screen-xl text-center max-md:flex-col mx-auto max-xl:px-6 mt-12 flex max-md:space-y-8 md:space-x-8'>
                        {
                            Blogs && Blogs.map((item, index) => {
                                return <SmallBlog image={item.image} title={item.title} desc={item.desc} date={item.date} like={item.like} comment={item.comment} />
                            })
                        }
                    </div>
                </div>
            </div>
        </>
    )
}