import React from "react";
import LogoPink from "../../assets/img/logo-pink.png";
import LogoBlue from "../../assets/img/logo-blue.png";
import { useState } from "react";
import { Link } from "react-scroll";
import {FaBars} from 'react-icons/fa';
import "../../assets/js/script"
export default function Header() {

    const [openTop, setOpenTop] = React.useState(false);

    return (
        <>
            <div className="fixed w-full bg-[#0300154d]  top-0 left-0 py-6" id="Header">
                <div className="max-w-6xl mx-auto max-xl:px-6 flex justify-between items-center">
                    <div className="w-[173px] h-[43px] bg-logo-pink bg-cover dark:bg-logo-blue">
                        
                    </div>
                    <div className="flex items-center">
                        <ul className="flex max-md:hidden">
                            <li>
                                <Link to={'Home'} activeClass="Active" className="Header__Link" spy={true} smooth={true} offset={0} duration={500}>Home</Link>
                            </li>
                            <li>
                                <Link to={'About'} activeClass="Active" className="Header__Link" spy={true} smooth={true} offset={0} duration={500}>About</Link>
                            </li>
                            <li>
                                <Link to={'Trainings'} activeClass="Active" className="Header__Link" spy={true} smooth={true} offset={0} duration={500}>Trainings</Link>
                            </li>
                            <li>
                                <Link to={'Team'} activeClass="Active" className="Header__Link" spy={true} smooth={true} offset={0} duration={500}>Team</Link>
                            </li>
                            <li>
                                <Link to={'Pricing'} activeClass="Active" className="Header__Link" spy={true} smooth={true} offset={0} duration={500}>Pricing</Link>
                            </li>
                            <li>
                                <Link to={'Blog'} activeClass="Active" className="Header__Link" spy={true} smooth={true} offset={0} duration={500}>Blog</Link>
                            </li>
                            <li>
                                <Link to={'Contact'} activeClass="Active" className="Header__Link" spy={true} smooth={true} offset={0} duration={500}>Contact</Link>
                            </li>
                        </ul>
                    </div>
                    <div className='cursor-pointer md:hidden' onClick={() => setOpenTop(!openTop)}>
                        <FaBars className={'text-mainPink dark:text-mainBlue text-[26px]'} />
                    </div>
                    <div className={
                        openTop
                            ? "w-full  bg-[#101010] h-[394px]  transition-all duration-500 left-0 top-[90px] fixed overflow-hidden md:hidden"
                            : "w-full h-0 translate-x-[0] bg-[#101010] left-0 top-[99px] transition-all duration-500 fixed overflow-hidden"
                    }>
                        <ul className='flex flex-col'>
                            <li className='Header__Link_Small'><Link to={'Home'} activeClass="Active" spy={true} smooth={true} offset={0} duration={500}>home </Link> </li>
                            <li className='Header__Link_Small'><Link to={'About'} activeClass="Active" spy={true} smooth={true} offset={0} duration={500}>about </Link> </li>
                            <li className='Header__Link_Small'><Link to={'Trainings'} activeClass="Active" spy={true} smooth={true} offset={0} duration={500}>Trainings </Link> </li>
                            <li className='Header__Link_Small'><Link to={'Team'} activeClass="Active" spy={true} smooth={true} offset={0} duration={500}>Team </Link> </li>
                            <li className='Header__Link_Small'><Link to={'Pricing'} activeClass="Active" spy={true} smooth={true} offset={0} duration={500}>Pricing </Link> </li>
                            <li className='Header__Link_Small'><Link to={'Blog'} activeClass="Active" spy={true} smooth={true} offset={0} duration={500}>Blog </Link> </li>
                            <li className='Header__Link_Small'><Link to={'Contact'} activeClass="Active" spy={true} smooth={true} offset={0} duration={500}>Contact </Link> </li>
                        </ul>
                    </div>
                </div>
            </div>
        </>
    )
}