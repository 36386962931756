import { MdLocationPin } from 'react-icons/md';
import { BsFillTelephoneFill } from 'react-icons/bs';
import { FaEnvelope, FaFacebookF, FaTwitter } from 'react-icons/fa';
import { TfiYoutube } from 'react-icons/tfi';
import { AiOutlineInstagram } from 'react-icons/ai';
import { TiArrowUpThick } from 'react-icons/ti';
import { Link } from 'react-scroll';
export default function Footer() {
    return (
        <>
            <div id="Contact" className="bg-[#23182C] dark:bg-[#111226] transition-all duration-300 pt-24 pb-24 relative ">
                <div className="max-w-screen-xl mx-auto flex  md:space-x-4 max-xl:px-6 max-md:flex-wrap">
                    <div className="w-full sm:w-1/2 md:w-2/5">
                        <div className="w-[173px] h-[43px] bg-logo-pink bg-cover dark:bg-logo-blue transition-all duration-300"></div>
                        <div className="mt-8 text-[#6f6078] dark:text-white transition-all duration-300 text-[16px]">
                            Quisque quis dignissim elit. Aliquam et augue aliquet orci maximus convallis id vitae augue. Phasellus elementum commodo aliquet. Sed vitae sem in arcu auctor pharetra vel ut erat.
                        </div>
                        <div className="mt-8 ">
                            <ul className='flex flex-col space-y-2'>
                                <li className="flex items-center space-x-4">
                                    <MdLocationPin className='text-mainPink dark:text-mainBlue transition-all duration-300 text-[14px]' /> <span className='text-[#6f6078] dark:text-white transition-all duration-300'>04360, NewYork, 33 Matehouse str., 12/4</span>
                                </li>
                                <li className="flex items-center space-x-4">
                                    <BsFillTelephoneFill className='text-mainPink dark:text-mainBlue transition-all duration-300 text-[14px]' /> <span className='text-[#6f6078] dark:text-white transition-all duration-300'>803-33-5644-99</span>
                                </li>
                                <li className="flex items-center space-x-4">
                                    <FaEnvelope className='text-mainPink dark:text-mainBlue transition-all duration-300 text-[14px]' /> <span className='text-[#6f6078] dark:text-white transition-all duration-300'>trainings@stargym.com</span>
                                </li>
                            </ul>
                        </div>
                        <div className="mt-16 ">
                            <ul className='flex space-x-4'>
                                <li className='bg-[#4E71A8] w-[40px] h-[40px] flex justify-center items-center rounded-full'>
                                    <FaFacebookF className='text-white' />
                                </li>
                                <li className='bg-[#1CB7EB] w-[40px] h-[40px] flex justify-center items-center rounded-full'>
                                    <FaTwitter className='text-white' />
                                </li>
                                <li className='bg-[#CA3737] w-[40px] h-[40px] flex justify-center items-center rounded-full'>
                                    <TfiYoutube className='text-white' />
                                </li>
                                <li className='bg-[#444444] w-[40px] h-[40px] flex justify-center items-center rounded-full'>
                                    <AiOutlineInstagram className='text-white' />
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="w-full sm:w-1/2 md:w-1/5 flex">
                        <div className='w-1/2 pt-2'>
                            <div className='text-mainPink dark:text-mainBlue transition-all duration-300 font-Fira font-bold text-[24px]'>Trainings</div>
                            <ul className='mt-8 text-white font-medium'>
                                <li className='transition-all duration-300 cursor-pointer hover:text-mainPink dark:hover:text-mainBlue'>
                                    <Link>Gym Training</Link>
                                </li>
                                <li className='transition-all duration-300 cursor-pointer hover:text-mainPink dark:hover:text-mainBlue'>
                                    <Link>Crossfit</Link>
                                </li>
                                <li className='transition-all duration-300 cursor-pointer hover:text-mainPink dark:hover:text-mainBlue'>
                                    <Link>Cardio</Link>
                                </li>
                            </ul>
                            <div className='text-mainPink dark:text-mainBlue transition-all duration-300 font-Fira font-bold text-[24px] mt-8'>Menu</div>
                            <ul className=' text-white font-medium'>
                                <li>
                                    <Link to={'Home'} className="transition-all duration-300 hover:text-mainPink dark:hover:text-mainBlue cursor-pointer" spy={true} smooth={true} offset={0} duration={500}>Home</Link>
                                </li>
                                <li>
                                    <Link to={'About'} className="transition-all duration-300 hover:text-mainPink dark:hover:text-mainBlue cursor-pointer" spy={true} smooth={true} offset={0} duration={500}>About</Link>
                                </li>
                                <li>
                                    <Link to={'Trainings'} className="transition-all duration-300 hover:text-mainPink dark:hover:text-mainBlue cursor-pointer" spy={true} smooth={true} offset={0} duration={500}>Trainings</Link>
                                </li>
                                <li>
                                    <Link to={'Team'} className="transition-all duration-300 hover:text-mainPink dark:hover:text-mainBlue cursor-pointer" spy={true} smooth={true} offset={0} duration={500}>Team</Link>
                                </li>
                                <li>
                                    <Link to={'Pricing'} className="transition-all duration-300 hover:text-mainPink dark:hover:text-mainBlue cursor-pointer" spy={true} smooth={true} offset={0} duration={500}>Pricing</Link>
                                </li>
                                <li>
                                    <Link to={'Blog'} className="transition-all duration-300 hover:text-mainPink dark:hover:text-mainBlue cursor-pointer" spy={true} smooth={true} offset={0} duration={500}>Blog</Link>
                                </li>
                                <li>
                                    <Link to={'Contact'} className="transition-all duration-300 hover:text-mainPink dark:hover:text-mainBlue cursor-pointer" spy={true} smooth={true} offset={0} duration={500}>Contact</Link>
                                </li>
                            </ul>
                        </div>
                        <div className='w-1/2 pt-2'>

                        </div>
                    </div>
                    <div className="w-full md:w-2/5 pt-2">
                        <div className='text-mainPink dark:text-mainBlue transition-all duration-300 font-Fira font-bold text-[24px]'>Contact Us</div>
                        <div className='mt-8'>
                            <form className='flex flex-col space-y-4'>
                                <div>
                                    <input type='text' className='w-full bg-[#352540] px-[17px] py-[18px] rounded-[16px] focus:outline-none text-[#fff]' placeholder='Your Name' />
                                </div>
                                <div>
                                    <input type='email' className='w-full bg-[#352540] px-[17px] py-[18px] rounded-[16px] focus:outline-none text-[#fff]' placeholder='Email' />
                                </div>
                                <div>
                                    <textarea type='email' className='w-full bg-[#352540] px-[17px] py-[18px] rounded-[16px] focus:outline-none text-[#fff]' placeholder='Text' />
                                </div>
                                <div>
                                    <button className='Shadow-Inset bg-mainPink dark:bg-mainBlue w-full h-[40px] rounded-[16px] text-white transition-all duration-300 hover:bg-white hover:text-[#422055]'>SEND</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                <div className='absolute cursor-pointer animate-bounce right-12 bottom-5 h-12 w-12 border-[2px] border-mainPink dark:border-mainBlue flex justify-center items-center text-[24px] text-mainPink dark:text-mainBlue rounded-full'>
                    <Link to='Home' spy={true} smooth={true} offset={0} duration={500}><TiArrowUpThick /></Link>
                </div>
            </div>
        </>
    )
}