import Im1 from "../../assets/img/_weight-mask.png";

export default function HomeWeight() {
    return (
        <>
            <div className="w-full bg-[#23182C] bg-weight-bg bg-no-repeat  bg-center bg-cover py-36">
                <div className="max-w-screen-xl flex mx-auto max-xl:px-6">
                    <div className="xl:w-1/2 flex flex-col justify-center">
                        <div className="text-white text-[40px] md:text-[62px] font-Fira font-black">HAVE EXTRA WEIGHT?</div>
                        <div className="transition-all duration-300 leading-3 text-mainPink dark:text-mainBlue text-[22px] md:text-[34px] font-Fira font-black">Get special nutrition program</div>
                        <div className="text-white mt-24 md:pr-14">
                            Fusce vestibulum, urna sit amet convallis ornare, erat urna viverra nisl, sed gravida tortor ipsum mollis augue. Curabitur sollicitudin risus ultricies ante congue, sit amet sollicitudin est ultricies. Aenean finibus libero ac nunc molestie lobortis.
                        </div>
                    </div>
                    <div className="w-1/2">

                    </div>
                </div>
            </div>
        </>
    )
}