import Im1 from "../../assets/img/_about_pink.jpg";
import Icon1 from "../../assets/img/_icon-1-pink.png";
import Icon2 from "../../assets/img/_icon-2-pink.png";
import Icon3 from "../../assets/img/_icon-3-pink.png";
import Icon4 from "../../assets/img/_icon-4-pink.png";
import Icon5 from "../../assets/img/_icon-5-pink.png";
import Icon6 from "../../assets/img/_icon-6-pink.png";

const iconsdata = [
    { img: Icon1, text: "Modern equipment" },
    { img: Icon2, text: "Coaches champions" },
    { img: Icon3, text: "The area of 7000 m2" },
    { img: Icon4, text: "Parking for 160 spots" },
    { img: Icon5, text: "Swimming pools available" },
    { img: Icon6, text: "Large bar with sports nutrition" },
]

export default function HomeAbout() {
    return (
        <>
            <div id="About" className="w-full py-12 max-xl:px-6">
                <div className="max-w-screen-xl mx-auto flex  max-md:flex-col space-y-6 md:bg-about-pink md:dark:bg-about-blue bg-no-repeat bg-right-top md:py-48 bg-contain">
                    <div className="md:w-1/2 flex items-center">
                        <div className="w-full">
                            <p className="text-[20px] sm:text-[25px] text-[#23182C] font-Fira font-black">
                                We can give you <span className="transition-all duration-300 text-mainPink dark:text-mainBlue">much more than others</span>
                            </p>
                            <p className="mt-6 max-sm:text-[13px] max-sm:text-justify leading-7">
                                In molestie malesuada dolor, in dignissim enim imperdiet id. Integer vel tellus justo. Mauris finibus ante in elit pellentesque molestie. Maecenas malesuada neque ac tempus vulputate. Morbi mollis eleifend tincidunt. Etiam mi sapien, hendrerit sit amet diam euismod, tempor ullamcorper libero. Quisque tempus urna arcu, vel placerat mauris viverra at. Suspendisse potenti. Morbi pharetra sapien justo, suscipit tincidunt lectus euismod egestas. Vivamus lectus leo, pellentesque nec magna vitae, aliquam venenatis libero.
                            </p>
                        </div>
                    </div>
                    {/* <div className=" md:w-1/2">
                        <img src={Im1} />
                    </div> */}
                </div>
                <div className="max-w-screen-xl mx-auto  mt-24">
                    <div className="w-full  text-center">
                        <p className="text-[22px] sm:text-[25px] text-[#23182C] font-Fira font-black">We can give you</p>
                        <p className="text-[24px] sm:text-[25px]  font-Fira font-black transition-all duration-300 text-mainPink dark:text-mainBlue">much more than others</p>
                    </div>
                    <div className="w-full flex max-lg:flex-wrap mt-12">
                        {/* {
                            iconsdata && iconsdata.map((item, index) => {
                                return <div className="w-full max-sm:mt-6 sm:w-1/2 flex flex-col items-center">
                                    <div className="w-[77px] h-[71px] bg-no-repeat bg-contain bg-center" style={{ backgroundImage: `url(${item.img})` }}>
                                    </div>
                                    <div className="text-[20px] py-2 sm:w-[90%] xl:w-[70%] font-Fira font-black text-center">
                                        {item.text}
                                    </div>
                                </div>
                            })
                        } */}
                        <div className="w-full max-sm:mt-6 sm:w-1/2 flex flex-col items-center">
                            <div className="w-[77px] h-[71px] bg-no-repeat bg-contain bg-center bg-icon-pink-1 dark:bg-icon-blue-1">
                            </div>
                            <div className="text-[20px] py-2 sm:w-[90%] xl:w-[70%] font-Fira font-black text-center">
                                Modern equipment
                            </div>
                        </div>
                        <div className="w-full max-sm:mt-6 sm:w-1/2 flex flex-col items-center">
                            <div className="w-[77px] h-[71px] bg-no-repeat bg-contain bg-center bg-icon-pink-2 dark:bg-icon-blue-2">
                            </div>
                            <div className="text-[20px] py-2 sm:w-[90%] xl:w-[70%] font-Fira font-black text-center">
                                Coaches champions
                            </div>
                        </div>
                        <div className="w-full max-sm:mt-6 sm:w-1/2 flex flex-col items-center">
                            <div className="w-[77px] h-[71px] bg-no-repeat bg-contain bg-center bg-icon-pink-3 dark:bg-icon-blue-3">
                            </div>
                            <div className="text-[20px] py-2 sm:w-[90%] xl:w-[70%] font-Fira font-black text-center">
                                The area of 7000 m2
                            </div>
                        </div>
                        <div className="w-full max-sm:mt-6 sm:w-1/2 flex flex-col items-center">
                            <div className="w-[77px] h-[71px] bg-no-repeat bg-contain bg-center bg-icon-pink-4 dark:bg-icon-blue-4">
                            </div>
                            <div className="text-[20px] py-2 sm:w-[90%] xl:w-[70%] font-Fira font-black text-center">
                                Parking for 160 spots
                            </div>
                        </div>
                        <div className="w-full max-sm:mt-6 sm:w-1/2 flex flex-col items-center">
                            <div className="w-[77px] h-[71px] bg-no-repeat bg-contain bg-center bg-icon-pink-5 dark:bg-icon-blue-5">
                            </div>
                            <div className="text-[20px] py-2 sm:w-[90%] xl:w-[70%] font-Fira font-black text-center">
                                Swimming pools available
                            </div>
                        </div>
                        <div className="w-full max-sm:mt-6 sm:w-1/2 flex flex-col items-center">
                            <div className="w-[77px] h-[71px] bg-no-repeat bg-contain bg-center bg-icon-pink-6 dark:bg-icon-blue-6">
                            </div>
                            <div className="text-[20px] py-2 sm:w-[90%] xl:w-[70%] font-Fira font-black text-center">
                                Large bar with sports nutrition
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </>
    )
}