import { Link } from "react-scroll";

export default function HomeStrong() {
    return(
        <>
            <div className="max-w-screen-xl max-xl:px-6 mx-auto py-12 md:py-24 md:bg-after-bg-strong-pink md:dark:bg-after-bg-strong-blue  bg-right-top bg-no-repeat">
                <div className="md:w-1/2 md:py-20">
                    <div className="text-[#23182C] font-Fira font-black text-[62px] leading-none">Be stronger</div>
                    <div className="text-mainPink dark:text-mainBlue font-Fira font-black text-[36px] mt-2 leading-none transition-all duration-300">change your body with stargym</div>
                    <div className="text-[#422055] mt-10 md:mt-16">Phasellus elementum commodo aliquet. Sed vitae sem in arcu auctor pharetra vel ut erat. Praesent mattis nisi laoreet ex tempus auctor. Nullam vitae arcu euismod, placerat mi id, ornare lorem. Morbi vulputate dui purus, ut sodales dolor hendrerit. Curabitur et purus at urna tristique pharetra.</div>
                    <div className="mt-10 md:mt-16">
                        <Link to="" className="px-[60px] py-[14px] bg-mainPink hover:bg-[#23182C] dark:bg-mainBlue transition-all duration-300 cursor-pointer text-white font-Fira Shadow rounded-[30px]">Start Now</Link>
                    </div>
                </div>
            </div>
        </>
    )
}