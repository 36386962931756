import React from "react"
import { useState, useEffect } from "react";
import {MdDarkMode , MdOutlineLightMode} from 'react-icons/md';

export default function ThemeSwitch() {
    const [theme, setTheme] = useState(null);

    useEffect(() => {
        if (window.matchMedia('(prefers-color-scheme: dark)').matches) {
            setTheme('dark');
        }
        else {
            setTheme('light');
        }
    }, [])

    useEffect(() => {
        if (theme === "dark") {
            document.documentElement.classList.add("dark");
        } else {
            document.documentElement.classList.remove("dark");
        }
    }, [theme]);

    const handleThemeSwitch = () => {
        setTheme(theme === "dark" ? "light" : "dark");
    };
    return (
        <>
            <button className="cursor-pointer z-50 w-6 h-6 text-[12px] flex justify-center items-center rounded-full border-[1px] border-mainBlue dark:border-mainPink  fixed right-8 top-24  animate-ping" onClick={handleThemeSwitch}>
            {
                theme === "dark" ? <MdOutlineLightMode className="text-mainPink" /> : <MdDarkMode className="text-mainBlue" />
            }

            </button>
        </>
    )
}