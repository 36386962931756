import {FaFacebookF , FaTwitter , FaInstagram} from 'react-icons/fa';


export default function TeamSmall(props) {
    return (
        <div className="w-full sm:w-[40%] mx-auto lg:w-1/4 max-lg:mt-8 rounded-[10px] overflow-hidden bg-white transition-all duration-300 hover:-translate-y-4 cursor-pointer">
            <div className={''}>
                <img src={props.image} className='w-full' />
            </div>
            <div className='flex flex-col space-y-1 items-center'>
                <div className='font-black font-Fira text-[#23182C] text-[24px]'>{props.nameBlack} <span className='transition-all duration-300 text-mainPink dark:text-mainBlue'>{props.namePink}</span></div>
                <div className='text-[14px]'>Age:<span className='font-black'>{props.age}</span></div>
                <div className='text-[14px]'>Experience:<span className='font-black'>{props.exp}</span></div>
                <div className='text-[14px]'>Specialization:<span className='font-black'>{props.proff}</span></div>
            </div>
            <div className='mt-4 border-t-[1px] border-[#EAE6EC] py-4'>
                <ul className='flex justify-center space-x-4'>
                    <li className='w-[28px] h-[28px] rounded-full border border-mainPink transition-all duration-300 dark:border-mainBlue flex justify-center items-center text-mainPink dark:text-mainBlue hover:text-black hover:border-black cursor-pointer dark:hover:border-black dark:hover:text-black'>
                        <a href={''}><FaTwitter /></a>
                    </li>
                    <li className='w-[28px] h-[28px] rounded-full border border-mainPink transition-all duration-300 dark:border-mainBlue flex justify-center items-center text-mainPink dark:text-mainBlue hover:text-black hover:border-black cursor-pointer dark:hover:border-black dark:hover:text-black'>
                        <a href={''}><FaFacebookF /></a>
                    </li>
                    <li className='w-[28px] h-[28px] rounded-full border border-mainPink transition-all duration-300 dark:border-mainBlue flex justify-center items-center text-mainPink dark:text-mainBlue hover:text-black hover:border-black cursor-pointer dark:hover:border-black dark:hover:text-black'>
                        <a href={''}><FaInstagram /></a>
                    </li>
                </ul>
            </div>
        </div>
    )
}