import Slider from "react-slick"
import TitlePinkBlack from "../PropsComponents/TitlePinkBlack";
import Im1 from "../../assets/img/_client-1.jpg";
import Im2 from "../../assets/img/_client-2.jpg";
import QuoteImg from "../../assets/img/quote.png";
export default function HomeOpinion() {

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows:false
    };

    return (
        <>
            <div className="w-full bg-white py-24 max-xl:px-6">
                <div className="max-w-screen-xl mx-auto">
                    <div className="text-center">
                        <TitlePinkBlack titleBlack={'What'} titlePink={'our clients say'} />
                    </div>
                    <div className="max-w-screen-md mx-auto mt-4">
                        <Slider {...settings}>
                            <div className="w-full text-center">
                                <div className="flex flex-col items-center">
                                    <div>
                                        <img src={Im1} className="rounded-full" />
                                    </div>
                                    <div>
                                        <div className="text-mainPink dark:text-mainBlue font-bold mt-2">Liza Brown</div>
                                        <div className="text-[14px] font-bold">Art-director, MLC Company</div>
                                    </div>
                                </div>
                                <div className="text-[17px] italic text-[#333333] mt-4">
                                    Morbi varius vehicula erat, at consequat enim consequat vitae. Cras eros mi, ultrices at posuere a, porttitor euismod urna. Aliquam sodales lacinia nisl non commodo. Donec sed neque vitae urna aliquet bibendum. Suspendisse lobortis venenatis mi, vitae commodo velit elementum.
                                </div>
                                <div className="mt-4 flex justify-center">
                                    <img src={QuoteImg} />
                                </div>
                            </div>
                            <div className="w-full text-center">
                                <div className="flex flex-col items-center">
                                    <div>
                                        <img src={Im2} className="rounded-full" />
                                    </div>
                                    <div>
                                        <div className="text-mainPink dark:text-mainBlue font-bold mt-2">Alexa Willson</div>
                                        <div className="text-[14px] font-bold">Art-director, MLC Company</div>
                                    </div>
                                </div>
                                <div className="text-[17px] italic text-[#333333] mt-4">
                                    Morbi varius vehicula erat, at consequat enim consequat vitae. Cras eros mi, ultrices at posuere a, porttitor euismod urna. Aliquam sodales lacinia nisl non commodo. Donec sed neque vitae urna aliquet bibendum. Suspendisse lobortis venenatis mi, vitae commodo velit elementum.
                                </div>
                                <div className="mt-4 flex justify-center">
                                    <img src={QuoteImg} />
                                </div>
                            </div>
                        </Slider>
                    </div>
                </div>
            </div>
        </>
    )
}

