export default function HomeSubscribe(params) {
    return(
        <div className="bg-mainPink dark:bg-mainBlue transition-all duration-300 w-full py-12">
            <div className="max-w-screen-xl mx-auto flex max-md:flex-col max-xl:px-6">
                <div className="w-full md:w-1/2 flex max-md:flex-col items-center  md:items-center md:space-x-24">
                    <div className=" font-black font-Fira text-[#FFDD55] text-[58px]">-10%</div>
                    <div className=" text-[24px] font-Fira font-black text-white"><span>Subscribe now</span><span className="text-black"> for discount</span></div>
                </div>
                <div className="w-full md:w-1/2 flex max-md:mt-6  items-center">
                    <div className="flex w-full">
                        <input type="text" className="w-2/3 h-[56px] py-[10px] px-[40px] rounded-l-[32px] text-[#23182C] text-[18px] font-light" placeholder="Your e-mail" />
                        <button className="w-1/3 bg-[#23182C] rounded-r-[32px] transition-all duration-300 text-white hover:bg-[#FFDD55] hover:text-[#23182C]">Subscribe</button>
                    </div>
                </div>
            </div>
        </div>
    )
}