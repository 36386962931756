import { Link } from "react-scroll";
import TitlePinkBlack from "../PropsComponents/TitlePinkBlack";

export default function HomeGift() {

    return (
        <>
            <div className="bg-[#F8F6F9] py-12">
                <div className="max-w-screen-xl mx-auto">
                    <div className="w-full text-center">
                        <TitlePinkBlack titleBlack={'Get Your'} titlePink={'Club card'} />
                        <p className="w-[80%] sm:w-[50%] lg:w-[40%] xl:w-[30%] mx-auto">
                            Club card entitles you to visit our club from 9:00
                            to 23:00 every day, seven days a week
                        </p>
                    </div>
                    <div className="mt-12 w-full flex md:space-x-12 max-md:space-y-6 max-xl:px-6 max-md:flex-col">
                        <div className="w-full sm:w-1/2 max-md:mx-auto  md:w-1/3 bg-white rounded-[36px] py-[50px] Shadow-Card transition-all duration-300 hover:-translate-y-4 cursor-pointer">
                            <div className="flex justify-center">
                                <div className="w-[139px] h-[95px] bg-card_pink_1 dark:bg-card_blue_1 transition-all duration-300 bg-cover bg-center"></div>
                            </div>
                            <div className="text-center mt-6">
                                <div className="text-mainPink uppercase dark:text-mainBlue text-[24px] font-Fira font-black">CLASSIC</div>
                                <div className="text-black text-[24px] font-Fira font-bold"><span className="text-[32px]">$90</span>/year</div>
                                <div className="mt-8 flex flex-col space-y-3">
                                    <div>
                                        <Link className="bg-mainPink dark:bg-mainBlue transition-all duration-300 ease-linear px-[40px] py-[8px] text-white rounded-[30px]">I want it</Link>
                                    </div>
                                    <div>
                                        <Link className="text-mainPink dark:text-mainBlue">details</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="w-full sm:w-1/2 max-md:mx-auto  md:w-1/3 bg-white rounded-[36px] py-[50px] Shadow-Card transition-all duration-300 hover:-translate-y-4 cursor-pointer">
                            <div className="flex justify-center">
                                <div className="w-[139px] h-[95px] bg-card_pink_2 dark:bg-card_blue_2 transition-all duration-300 bg-cover bg-center"></div>
                            </div>
                            <div className="text-center mt-6">
                                <div className="text-mainPink uppercase dark:text-mainBlue text-[24px] font-Fira font-black">LUX</div>
                                <div className="text-black text-[24px] font-Fira font-bold"><span className="text-[32px]">$160</span>/year</div>
                                <div className="mt-8 flex flex-col space-y-3">
                                    <div>
                                        <Link className="bg-mainPink dark:bg-mainBlue transition-all duration-300 ease-linear px-[40px] py-[8px] text-white rounded-[30px]">I want it</Link>
                                    </div>
                                    <div>
                                        <Link className="text-mainPink dark:text-mainBlue">details</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="w-full sm:w-1/2 max-md:mx-auto  md:w-1/3 bg-white rounded-[36px] py-[50px] Shadow-Card transition-all duration-300 hover:-translate-y-4 cursor-pointer">
                            <div className="flex justify-center">
                                <div className="w-[139px] h-[95px] bg-card_pink_3 dark:bg-card_blue_3 transition-all duration-300 bg-cover bg-center"></div>
                            </div>
                            <div className="text-center mt-6">
                                <div className="text-mainPink uppercase dark:text-mainBlue text-[24px] font-Fira font-black">PREMIUM</div>
                                <div className="text-black text-[24px] font-Fira font-bold"><span className="text-[32px]">$299</span>/year</div>
                                <div className="mt-8 flex flex-col space-y-3">
                                    <div>
                                        <Link className="bg-mainPink dark:bg-mainBlue transition-all duration-300 ease-linear px-[40px] py-[8px] text-white rounded-[30px]">I want it</Link>
                                    </div>
                                    <div>
                                        <Link className="text-mainPink dark:text-mainBlue">details</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}