import React from "react";
import HomeFirst from "./Components/HomeFirst";
import HomeAbout from "./Components/HomeAbout";
import HomeTrainings from "./Components/HomeTrainings";
import HomeStrong from "./Components/HomeStrong";
import HomeTrainingPlan from "./Components/HomeTrainingPlan";
import HomeTeam from "./Components/HomeTeam";
import HomeWeight from "./Components/HomeWeight";
import HomeLine from "./Components/HomeLine";
import HomeGift from "./Components/HomeGift";
import HomeOpinion from "./Components/HomeOpinions";
import HomeSkills from "./Components/HomeSkills";
import HomeBlog from "./Components/HomeBlog";
import HomeSubscribe from "./Components/HomeSubscribe";
export default function Home() {
  
    return (
        <>
            <HomeFirst />
            <HomeAbout /> 
            <HomeTrainings />  
            <HomeStrong />
            <HomeTrainingPlan />
            <HomeTeam />
            <HomeWeight />
            <HomeLine />
            <HomeGift />
            <HomeOpinion />
            <HomeSkills />
            <HomeBlog />
            <HomeSubscribe />
        </>
    )
}