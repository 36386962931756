import $ from 'jquery';
import Img1 from "../img/_slider-1.jpg";
import Img2 from "../img/_slider-2.jpg";
import Img3 from "../img/_slider-3.jpg";

$(window).scroll(function(){
    if ($(window).scrollTop() >= 50) {
        $('#Header').removeClass('bg-[#0300154d]')
        $('#Header').addClass('bg-[#000000]');
        $('#Header').css("z-index" , "999999999999999999999");
    }
    else {
        $('#Header').removeClass('bg-[#000000]');
        $('#Header').addClass('bg-[#0300154d]')

    }
})

$(document).ready(function () {
   
    (function changeBg() {
        var img_array = [Img1, Img2 ,Img3 ],
        _nxtIndex = 0,
        _curIndex = 0,
        interval = 3000;
            function nextIndex(){ _nxtIndex =  (  _nxtIndex + 1) % img_array.length; return _nxtIndex;};
            
            function shiftIndexes(){
                _curIndex = _nxtIndex;
                nextIndex();
            }
            
            function assignBackgrounds(){
                for (var i=0; i<img_array.length;i++){
                    
                    $('#background-slide'+i).css('backgroundImage', function () {
                             return 'url(' + img_array[i] + ')';
                         });
                     if(i==0){
                         $('#background-slide'+i).css('opacity', 1);
                     }
                     else{
                         $('#background-slide'+i).css('opacity', 0);
                     }
                }
             }
            
            function startBackgroundOpacityToggle() {
              //console.log("in startBackgroundOpacityToggle. _curIndex = "+_curIndex);
           let   elem = $('#background-slide'+_curIndex);
              elem.animate({
                         opacity: (elem.css('opacity')==0) ? 1 : 0
                     }, {
                         duration: 5000,
                         start: finishBackgroundOpacityToggle         
                       }); };
                   
            function finishBackgroundOpacityToggle (){
              //console.log("in finishBackgroundOpacity. _nxtIndex = "+_nxtIndex);
            let    elem = $('#background-slide'+_nxtIndex);
                elem.animate({
                        opacity: (elem.css('opacity')==0) ? 1 : 0
                    },  {
                        duration: 5000,
                        complete: runSlider
                      });
            
            };
            
            function runSlider(){
                shiftIndexes();
                setTimeout(startBackgroundOpacityToggle,interval);
            } 
            
            assignBackgrounds();
            runSlider();
    })(); 
});



