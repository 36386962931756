import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
// import 'react-tabs/style/react-tabs.css';
import Img1 from "../../assets/img/_trainings-1.jpg";
import Img2 from "../../assets/img/_trainings-2.jpg";
import Img3 from "../../assets/img/_trainings-3.jpg";
export default function HomeTrainings() {
    return (
        <>
            <div id="Trainings" className="max-w-screen-xl mx-auto max-xl:px-6 pt-12 pb-24">

                <div className='w-full py-12 text-center'>
                    <p className="text-[22px] sm:text-[36px] text-[#23182C] font-Fira font-black">Trainings</p>
                </div>

                <div className='w-full'>
                    <Tabs className={'flex justify-center max-lg:flex-col'}>
                        <div className='max-lg:rounded-t-[36px] lg:rounded-l-[36px] overflow-hidden'>
                            <TabPanel className='w-full'>
                                <div className='h-full'>
                                    <img src={Img1} className='lg:h-[570px] max-xl:w-full' />
                                </div>
                            </TabPanel>
                            <TabPanel className={'w-full'}>
                                <div className='h-full'>
                                    <img src={Img2} className='lg:h-[570px] max-xl:w-full' />
                                </div>
                            </TabPanel>
                            <TabPanel className={'w-full'}>
                                <div className='h-full'>
                                    <img src={Img3} className='lg:h-[570px] max-xl:w-full' />
                                </div>
                            </TabPanel>
                        </div>
                        <TabList className={'flex max-md:flex-col lg:flex-col  TabList max-lg:rounded-b-[36px] lg:rounded-r-[36px] overflow-hidden'}>
                            <Tab className={'For_after_before1 p-0  lg:h-[190px]  m-0 focus:outline-none focus:border-none'}>
                                <div  className={'border-none focus:border-none max-lg:pb-[20px] max-md:pl-[10px] md:pl-[30px] lg:pl-[50px] lg:pr-[50px] xl:pr-[120px] pt-[28px] xl:pt-[56px]'}>
                                        <div className=''>
                                            <p className='Header_text text-white'>Gym Trainings</p>
                                            <p className='Header_small'>Anaerobic training with heavy weights</p>
                                        </div>           
                                </div>
                            </Tab>
                            <Tab className={'For_after_before2 p-0  lg:h-[190px]  m-0 focus:outline-none focus:border-none'}>
                                <div  className={'border-none focus:border-none max-lg:pb-[20px] max-md:pl-[10px] md:pl-[30px] lg:pl-[50px] lg:pr-[50px] xl:pr-[120px] pt-[28px] xl:pt-[56px]'}>
                                        <div className=''>
                                            <p className='Header_text text-white'>Gym Trainings</p>
                                            <p className='Header_small'>Anaerobic training with heavy weights</p>
                                        </div>           
                                </div>
                            </Tab>
                            <Tab className={'For_after_before3 p-0  lg:h-[190px]  m-0 focus:outline-none focus:border-none'}>
                                <div  className={'border-none focus:border-none max-lg:pb-[20px] max-md:pl-[10px] md:pl-[30px] lg:pl-[50px] lg:pr-[50px] xl:pr-[120px] pt-[28px] xl:pt-[56px]'}>
                                        <div className=''>
                                            <p className='Header_text text-white'>Gym Trainings</p>
                                            <p className='Header_small'>Anaerobic training with heavy weights</p>
                                        </div>           
                                </div>
                            </Tab>
                           
                      
                        </TabList>


                    </Tabs>
                </div>
            </div>
        </>
    )
}