import { Routes, Route } from "react-router-dom";
import Header from "./Pages/Layouts/Header";
import Home from "./Pages/home";
import Footer from "./Pages/Layouts/Footer";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import ThemeSwitch from "./Pages/Components/ThemeSwitch";
function App() {

  return (

    <>
      <ThemeSwitch />
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
      </Routes>
      <Footer />
    </>
  );
}

export default App;
