import {BsFillSuitHeartFill} from 'react-icons/bs';
import {FaCommentDots} from "react-icons/fa";
export default function SmallBlog(props) {
    return(
        <>
           <div className="w-full md:w-1/3 bg-[#F8F6F9] Shadow-Blog transition-all duration-300 cursor-pointer hover:bg-white hover:-translate-y-4 rounded-[10px] overflow-hidden">
                <div className="">
                    <img src={props.image} className='w-full' />
                </div>
                <div className="px-[26px] py-[22px]">
                    <div className='font-Fira font-black text-mainPink dark:text-mainPink text-[20px] text-left'>{props.title}</div>
                    <div className='text-[14px] text-left mt-6'>{props.desc}</div>
                </div>
                <div className="flex justify-between border-t-[1px] border-[#EAE6EC] pt-[8px] pb-[4px] px-[26px]">
                    <div className='text-[#85768f] text-[13px]'>{props.date}</div>
                    <div className="flex space-x-2">
                        <div className='flex items-center space-x-2'><BsFillSuitHeartFill className='text-mainPink dark:text-mainBlue transition-all duration-300' /> <span>{props.like}</span></div>
                        <div className='flex items-center space-x-2'><FaCommentDots className='text-mainPink dark:text-mainBlue transition-all duration-300' /> <span>{props.comment}</span></div>
                    </div>
                </div>
           </div>
        </>
    )
}